(() => {
  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
  };

  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  let clipIdx = 0;

  const generateSegment = ({ src, color }, path) => {
    clipIdx++;
    return `
    <div style="position: absolute; top:0; left:0; right:0; bottom:0;">
      <div style="position: absolute; top:0 left:0; right: 0; bottom: 0;">
        <svg height="100%" width="100%" viewBox="0 0 1 1">
          <defs>
              <clipPath id="svgPath_${clipIdx}" clipPathUnits="objectBoundingBox">
                <path d="${path}"/>
              </clipPath>
          </defs>
        </svg>
      </div>
      ${
        color
          ? `<div style="height: 100%; width: 100%; background-color: ${color}; clip-path: url(#svgPath_${clipIdx});"></div>`
          : ""
      }
      ${
        src
          ? `<img src="${src}" style="height: 100%; width: 100%; clip-path: url(#svgPath_${clipIdx});"/>`
          : ""
      }
    </div>
  `;
  };

  const generatePieChart = (paths) => {
    return `
    <div style="position: relative; height:100%; width: 100%;">
      ${paths}
    </div>
  `;
  };

  const initPieCharts = (elements) => {
    const cos = Math.cos;
    const sin = Math.sin;
    const π = Math.PI;

    const f_matrix_times = ([[a, b], [c, d]], [x, y]) => [
      a * x + b * y,
      c * x + d * y,
    ];
    const f_rotate_matrix = (x) => [
      [cos(x), -sin(x)],
      [sin(x), cos(x)],
    ];
    const f_vec_add = ([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2];

    const f_svg_ellipse_arc = ([cx, cy], [rx, ry], [t1, Δ], φ) => {
      Δ = Δ % (2 * π);
      const rotMatrix = f_rotate_matrix(φ);
      const [sX, sY] = f_vec_add(
        f_matrix_times(rotMatrix, [rx * cos(t1), ry * sin(t1)]),
        [cx, cy]
      );
      const [eX, eY] = f_vec_add(
        f_matrix_times(rotMatrix, [rx * cos(t1 + Δ), ry * sin(t1 + Δ)]),
        [cx, cy]
      );
      const fA = Δ > π ? 1 : 0;
      const fS = Δ > 0 ? 1 : 0;

      let path =
        "M " +
        sX +
        " " +
        sY +
        " A " +
        [rx, ry, (φ / (2 * π)) * 360, fA, fS, eX, eY].join(" ");
      path += ` L ${cx} ${cy}  Z`;

      return path;
    };

    const fullPath =
      "M 0.5 0.5 m 0.5, 0 a 0.5,0.5 0 1,0 -1 ,0 a 0.5,0.5 0 1,0 1,0 ";

    const makePath = (idx, count) => {
      if (count === 1) {
        return fullPath;
      }

      const angle = (2 * π) / count;

      const beginningAngle = idx * angle;

      const arcPath = f_svg_ellipse_arc(
        [0.5, 0.5],
        [0.5, 0.5],
        [beginningAngle, angle],
        0
      );

      return arcPath;
    };

    const charts = elements || document.querySelectorAll("pie-chart");

    for (const chart of charts) {
      chart.style.display = "block";
      chart.style.width = "100%";
      chart.style.height = "100%";

      const element = document.createElement("div");

      element.style.width = "100%";
      element.style.height = "100%";

      const dataPie = JSON.parse(decodeHtml(chart.dataset.pie));

      const segments = dataPie.map((val, i) => {
        const path = makePath(i, dataPie.length);

        const data = isColor(val) ? { color: val } : { src: val };

        const segment = generateSegment(data, path);

        return segment;
      });

      const allPaths = segments.join("\n");

      element.innerHTML = generatePieChart(allPaths);

      chart.appendChild(element);
    }
  };

  window.initPieCharts = initPieCharts;
})();
