import TomSelect from '@remotedevforce/tom-select';

/**
 * Implement Tom-Select support with
 * 1. AJAX Support
 * 2. Multiple element support
 * 3. Dynamic element support
 */

export const loadTomSelect = (elem, ajax) => {
    const ajaxExtraConfig = {
        labelField: 'text',
        valueField: 'value',
        searchField: 'text',
        openOnFocus: elem.dataset.tsOpenOnPrompt !== "false",
        preload: true,
        load: (query, callback) => {
            const params = new URLSearchParams(elem.dataset.url.split('?')[1]);
            params.append('query', query);

            fetch(`${elem.dataset.url.split("?")[0]}?${params.toString()}`)
              .then((response) => response.json())
              .then((json) => {
                  callback(json.items);
              })
              .catch(() => {
                  callback();
              });
        },
        render: {
            no_results: function (data, escape) {
                const text = document.body.dataset.locale === "fr" ? "Aucun résultat" : "No results found";
                return `<div class="no-results">${text}</div>`;
            }
        }
    };

    const createConfig = { create: elem.dataset.dynamic == "true" };

    const renderOverrides = {
        item: (data, escape) => {
            const customClass = data.$option ? data.$option.classList.toString() : '';
            return `<div class="${customClass}">${escape(data.text)}</div>`;
        },
    };

    let ts;
    // Multiple or single selection
    if (elem.getAttribute('multiple')) {
        ts = new TomSelect(elem, {
            plugins: ['remove_button'],
            render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig)
        });
    } else {
        ts = new TomSelect(elem, {
            render: renderOverrides, ...createConfig, ...(ajax && ajaxExtraConfig)
        });
    }
    // Behavior improvement for multiple
    ts.on('item_add', function () {
        if (elem.multiple) {
            this.input.nextElementSibling.querySelector('input').value = '';
            this.refreshOptions();
        }
    });
    // Event listener to hide dropdown on input value change
    ts.on('type', function (value) {
        if (elem.dataset.tsOpenOnPrompt === "false" && value.trim() === "") {
            this.close();
        }
    });
};
